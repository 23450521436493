$secondary: #1e293b;
$gray-50: #F9FAFB;
$gray-100: #F3F4F6;
$gray-300: #D1D5DB;
$gray-500: #6B7280;
$gray-600: #4B5563;
$gray-700: #374151;
$gray-800: #1F2937;
$gray-900: #111827;
$gray-default: #6B7280;

$green-600: #16A34A;
$slate-100: #F1F5F9;
$slate-600: #475569;
$slate-800: #1E293B;


$magnoliawhite: #E5E5EF;


$light: #F9FAFB;
$primary: #0891B2;
$success: #2B7F75;
$white: #fff;
$antiflashwhite: #E6E9EB;
$warning: #DBAA00;
$whitesmoke: #F9FAFB;
$danger: #DC2626;
$mintcream: #f0f9f3;
$mustardyellow: #dfb31c;
$coolmint: #dcf3ff;
$dark: #223322;
$gainsboro: #dee2e6;



$eerieblack: #1b1f2326;
$blackrgb: #0000001A;
$taupegray: #958f8f;
$nickel: #6C7278;


$darkblue: #797979;
$silver: #79797980;