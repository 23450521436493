.is-loading {
  position: relative
}

.is-loading:after {
  content: '';
  margin: 0px auto;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 3px solid rgba(0, 0, 0, 0.1);
  border-right: 3px solid rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  opacity: .8;
  border-left: 3px solid #446084;
  -webkit-animation: spin .6s infinite linear;
  animation: spin .6s infinite linear;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  pointer-events: none
}

.is-loading:after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  z-index: 99
}

.is-loading:before {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  z-index: 9;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}