@import "../../../assets/scss/theme-color.scss";

.sidebar-colums {
	width: 255px !important;
	height: 100vh;
	background-color: $secondary;
	// border-right: 2px solid $antiflashwhite;
	padding: 0px 8px;
	display: flex;
	flex-direction: column;

	.main-logo {
		padding: 20px 15px;
	}

	ul {
		margin-bottom: 20px;

		li {
			margin-bottom: 10px;

			a {
				display: block;
				padding: 12px;
				color: $gray-300;

				&:hover {
					color: $white
				}

				span {
					margin-right: 12px;
				}
			}

			.active {
				background-color: $gray-900;
				border-radius: 6px;
				color: $white;
			}
		}
	}

	.logout-col {
		// padding-left: 14px;
		padding-bottom: 10px;

		.logout-button {
			display: block;
			padding: 12px;
			color: $gray-300;
			font-size: 14px;
			font-weight: 500;

			&:hover {
				color: $white
			}


			span {
				margin-right: 12px;
			}
		}
	}
}