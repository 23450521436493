@import "../../../../assets/scss/theme-color.scss";

.member-card-row {
  margin: 20px;
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 0px;

  .member-card-heading {
    h2 {
      margin-bottom: 0px;
    }

    p {
      color: $gray-default;
      font-size: 15px;
    }
  }

  .member-leaderboard-card {
    margin-top: 24px;

    .table {
      padding: 18px;
      background-color: $white;
      box-shadow: 0px 2px 4px -1px hwb(0 0% 100% / 0.059), 0px 4px 6px -1px #0000001a;
      border-collapse: collapse;
      border-radius: 15px;
      overflow: hidden;
      width: 100%;

      thead {
        tr {
          vertical-align: middle;

          th {
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            color: $slate-800;
            padding-top: 15px;
            background-color: $gray-100;
            padding-bottom: 15px;

            &:first-child {
              padding-left: 20px;
            }

          }
        }

      }

      tbody {
        tr {
          vertical-align: middle;

          td {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $gray-500;
            padding-top: 15px;
            padding-bottom: 15px;

            &:first-child {
              padding-left: 20px;
            }

            h6 {
              margin-bottom: 0px;
            }
          }
        }
      }
    }

    h3 {
      margin-bottom: 0px;

      span {
        font-weight: 400;
        font-size: 15px;
      }
    }

    .pagination {
      justify-content: end;
    }
  }
}

.new-member-button {

  .btn-primary {
    padding: 11px;
    background-color: #0891B2;
    border-color: #0891B2;
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px #0000000d;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    &:hover {
      background-color: #1c7d95;
    }
  }
}