@import "../../../assets/scss/theme-color.scss";


.slider-bar-section {
	background-color: #f9fafb;

	.main-content-right {
		width: calc(100% - 255px);
		height: 100%;
		transition: all .8s ease;
		padding-left: 12px;

		.dashboard-row {
			margin: 20px;
			height: 90vh;
			overflow-y: scroll;
			overflow-x: hidden;
			margin-bottom: 0px;

			.registered-card {
				.card {
					padding: 24px;
					border: 1px solid $slate-100;
					box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
					border-radius: 8px;
					background-color: $white;

					p {
						color: $gray-500;
						font-weight: 500;

					}

					h2 {
						margin-bottom: 0px;

						span {
							color: $green-600;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							margin-left: 10px;
						}

						.workouts-days {
							color: $danger;
						}
					}

					.flex-grow-1 {
						margin-left: 20px;
					}
				}
			}

			.most-populas-card {
				margin-top: 24px;

				.card {
					padding: 18px;
					border-radius: 8px;
					background-color: $white;
					border: 1px solid $slate-100;
					box-shadow: 0px 2px 4px -1px hwb(0 0% 100% / 0.059), 0px 4px 6px -1px #0000001a;

					h3 {
						font-style: normal;

						span {
							font-size: 15px;
							font-weight: 400;
						}
					}

					h6 {
						color: $gray-800;
					}
				}

				.fullbody-col {
					padding: 18px;

					.full-body-card {
						background-color: $gray-50;
						padding: 8px;
						border-radius: 9px;
						margin-top: 12px;

						h2 {
							margin-bottom: 0px;
							font-size: 15px;
							line-height: 22px;
						}

						p {
							font-size: 13px;
							font-style: normal;
							line-height: normal;
							color: $gray-600;
						}

						.flex-shrink-0 {
							margin-right: 12px;
						}
					}
				}

			}

			.member-sign-up {
				margin-top: 24px;

				.card {

					padding: 18px;
					border-radius: 8px;
					background-color: $white;
					border: 1px solid $slate-100;
					box-shadow: 0px 2px 4px -1px hwb(0 0% 100% / 0.059), 0px 4px 6px -1px #0000001a;

					.membersing-up-inner {


						.member-sign-heading {
							float: left;
						}
					}

					.monthly-tabs {
						.tab-content {
							border-top: 1px solid$magnoliawhite;
							margin-top: 10px;
						}

						.nav-pills {
							justify-content: end;

							.nav-item {
								.nav-link {
									line-height: 20px;
									font-weight: 400;
									padding: 10px 15px 11px 15px;
									border-radius: 12px;
									color: $slate-600;
								}

								.active {
									background-color: $gray-800;
									color: $white;

								}
							}
						}

						.ifarm-img {
							width: 100%;
						}
					}
				}
			}

			.member-leaderboard-card {
				margin-top: 24px;

				.table {
					padding: 18px;
					background-color: $white;
					box-shadow: 0px 2px 4px -1px hwb(0 0% 100% / 0.059), 0px 4px 6px -1px #0000001a;
					border-collapse: collapse;
					border-radius: 15px;
					overflow: hidden;
					width: 100%;

					thead {
						tr {
							vertical-align: middle;

							th {
								font-size: 13px;
								font-style: normal;
								font-weight: 500;
								line-height: 16px;
								color: $slate-800;
								padding-top: 15px;
								background-color: $gray-100;
								padding-bottom: 15px;

								&:first-child {
									padding-left: 20px;
								}

							}
						}

					}

					tbody {
						tr {
							vertical-align: middle;

							td {
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 20px;
								color: $gray-500;
								padding-top: 15px;
								padding-bottom: 15px;

								&:first-child {
									padding-left: 20px;
								}

								h6 {
									margin-bottom: 0px;
								}
							}
						}
					}
				}

				h3 {
					margin-bottom: 0px;

					span {
						font-weight: 400;
						font-size: 15px;
					}
				}

			}
		}
	}

}