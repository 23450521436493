@import "../../../assets/scss/theme-color.scss";

.main-header {
	background-color: $white;
	padding: 10px 20px;
	border-bottom: 1px solid $magnoliawhite;

	.navbar {

		ul {
			li {
				p {
					a {
						&:hover {
							color: $dark;
						}

						span {
							margin-right: 10px;
						}
					}
				}
			}
		}

	}

	.user-list {
		ul {
			margin-bottom: 0px;

			li {
				margin-right: 12px;

				&:last-child {
					margin-right: 0px;
				}

				.navbar-icon-botton {
					background-color: $gray-100;
					border: 0px;
					padding: 0px;
					border-radius: 20px;
					display: flex;
					text-align: center;
					align-items: center;
					height: 30px;
					width: 30px;
					justify-content: center;

					svg {
						font-size: 12px;
					}
				}
			}
		}
	}

	.user-profile {

		.user-names {
			margin: 10px 0px;
		}

		.dropdown-menu {
			padding: 0px;
			border-radius: 8px;
			left: -110px;
			border: 0px;
			background: $white;
			box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;

			.dropdown-item {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				padding: 8px 16px;
				color: $gray-700;

				&:hover {
					color: $white;
					background-color: $primary;
				}

				&:focus {
					color: $white;
					background-color: $primary;
				}
			}
		}

		.avtar-user {
			background: $primary;
			border: 0px;
			padding: 0px;
			border-radius: 20px;
			display: flex;
			text-align: center;
			align-items: center;
			height: 30px;
			width: 30px;
			justify-content: center;

			span {
				color: $white;
				font-size: 15px;
			}
		}

	}
}