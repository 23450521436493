@import "bootstrap/scss/bootstrap.scss";
@import "./button-loading.scss";
@import "./form-control.scss";
@import "./loading.scss";
@import "../../assets/scss/theme-color.scss";



@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

body,
html {
  padding: 0px;
  margin: 0px;
  background-color: $gray-50;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $gray-900;
}

a {
  color: $gray-500;
  text-decoration: none;
}

p {
  font-weight: 400;
  margin-bottom: 0px;
  font-style: normal;
  line-height: 22px;
}

ul {
  margin-bottom: 0px;
  padding-left: 0px;
}

h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

h3 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}

h5 {
  font-size: 24px;
  line-height: 24px;
}



.align-middles {
  display: flex;
  align-items: center;
}

.space-between {
  justify-content: space-between;
  display: flex;
}

.justify-center {
  justify-content: center;
  display: flex;
}