.form-group {
  position: relative;
  margin-bottom: 15px;

  label {
    color: rgba(51, 48, 60, 0.87);
    font-weight: 300;
    line-height: 0.9375rem;
    margin-bottom: 0.375rem;
  }

  &.search-input {
    .input-group {
      border: 1px solid $gray-300;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;

      input {
        border-radius: 8px !important;
        padding-left: 41px;
        height: 45px;

      }

      .input-group-text {
        background-color: transparent;
        border-right: 0;
        padding: 0 0 0 0.875rem;
        bottom: 0;
        position: absolute;
        top: 0;
        z-index: 9;

      }

      .form-control {
        border: 1px solid $gray-300;
        box-shadow: 0px 1px 2px 0px #0000000d;

        &:focus {
          border: 1px solid $gray-300;
          box-shadow: 0px 1px 2px 0px #0000000d;
        }
      }
    }
  }



  .react-select__control {
    border-color: $border-color;
    border-radius: 5px;
    box-shadow: none;

    &:focus,
    &:hover {
      border: 0.0625rem solid #7367f0;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
    }

    .react-select__indicators {
      // span {
      //   display: none;
      // }

      .react-select__indicator {
        padding-left: 0;

        svg {
          color: rgba(51, 48, 60, 0.6);
        }

        &:first-child {
          padding-right: 5px;
        }
      }
    }
  }

  .form-select,
  .react-dropdown {
    .react-select__value-container {
      font-size: 1rem;
      font-weight: 300;
      padding: 0.1875rem 0 0.1875rem 0.49rem;
      text-transform: capitalize;

      @media only screen and (max-width: 1279px) {
        font-size: 0.875rem;
        padding: 0.1875rem 0 0.1875rem 0.9375rem;
      }

      .react-select__placeholder {
        font-size: 0.9375rem;
        text-transform: none;
        border-radius: 5px;
        // color: rgba(51, 48, 60, 0.87);
        font-weight: 300;

        @media only screen and (max-width: 1279px) {
          font-size: 0.875rem;
        }
      }

      .react-select__single-value {
        font-size: 0.9375rem;
        text-transform: none;
        border-radius: 5px;
        color: rgba(51, 48, 60, 0.87);
        // font-weight: 300;
      }
    }

    .react-select__menu {
      border: 0;
      border-radius: 5px;
      box-shadow: 0 10px 20px hsla(251, 6%, 66%, 0.4);
      padding: 0.5rem;
      z-index: 99;

      .react-select__menu-list {
        max-height: 275px;

        .react-select__option {
          border-radius: 5px;
          color: rgba(51, 48, 60, 0.87);
          font-size: 0.9375rem;
          letter-spacing: 0.01em;
          line-height: 1.125rem;
          margin-bottom: 0.375rem;
          padding: 0.6875rem 1.25rem;

          &.react-select__option--is-selected {
            background: $primary;
            color: #fff !important;
          }
        }
      }

      .react-select__option--is-disabled {
        opacity: 0.5;
        background-color: #deebff;
      }
    }
  }

  &.iconSearch {
    .react-select__value-container {
      padding-left: 40px;
    }
  }

  .invalid {
    background-image: none;
    border-color: #ea5455;

    .react-select__control {
      background-image: none;
      border-color: #ea5455;
    }

    .react-select__control {
      .react-select__indicator {
        svg {
          color: #ea5455 !important;
        }
      }
    }

    .react-select__indicator {
      svg {
        color: #ea5455 !important;
      }
    }
  }

  .customer-text-search {
    width: fit-content;
    padding: 11px;
    position: absolute;
    z-index: 99;
    background: transparent;
    border: 0;
    margin-right: 10px;
  }

  .form-check {
    .form-check-input {
      width: 20px;
      height: 20px;
      margin-right: 10px;

      @media only screen and (max-width: 1300px) {
        margin-right: 5px;
      }
    }

    .form-check-label {
      margin-bottom: 0;
      vertical-align: bottom;
    }

    &.form-check-inline {
      margin-top: 10px;
      margin-bottom: 10px;

      @media only screen and (max-width: 1300px) {
        margin-right: 8px;
      }
    }
  }



  &.search-hidden {
    .react-select__control {
      display: none;
    }

    .react-select__menu {
      margin: 0;
    }
  }

  input[type="number"] {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
    }
  }

  .react-select__indicator-separator {
    width: 0;
  }
}

input::placeholder,
textarea::placeholder {
  font-weight: 300 !important;
  color: rgba(51, 48, 60, 0.38) !important;
  opacity: 0.5;
}

.asyncselect {
  background-image: none;
  border-color: rgba(51, 48, 60, 0.6);
  padding: 0 0.5rem;

  .react-select__indicators {
    .react-select__dropdown-indicator {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

